import React, { useCallback, useRef, useState } from 'react';
import cc from 'classcat';
import AnimateHeight from 'react-animate-height';

import PlusIcon from '@/images/icons/plus.svg';
import SubIcon from '@/images/icons/sub.svg';

import styles from './styles.module.scss';

export interface Props {
  title?: string;
  slug?: string;
  content?: string;
  children?: React.ReactNode;
}

const Accordion = ({ title, content, children }: Props) => {
  const [active, setActive] = useState(false);

  const ref = useRef<HTMLLIElement>(null);

  const toggleActive = useCallback(() => {
    setActive(!active);
  }, [active]);

  return (
    <li ref={ref} className={cc([styles.accordion, { [styles.active]: active }])} data-testid="accordion">
      <div className={styles.accordionItemWrapper}>
        <div
          className={styles.accordionItemHeader}
          role="button"
          tabIndex={0}
          onClick={toggleActive}
          onKeyPress={toggleActive}
        >
          <h3>{title}</h3>
          <div className={styles.accordionButton}>{active ? <SubIcon /> : <PlusIcon />}</div>
        </div>
        <AnimateHeight duration={200} height={active ? 'auto' : 0}>
          <div className={styles.accordionItemBody}>
            {content ? ( // eslint-disable-next-line react/no-danger
              <p dangerouslySetInnerHTML={{ __html: content }} />
            ) : null}
            {children}
          </div>
        </AnimateHeight>
      </div>
    </li>
  );
};

export default Accordion;
