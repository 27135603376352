import React from 'react';

import Accordion from '@/components/Accordion';

import styles from './styles.module.scss';

interface Props {
  items: AccordionFragment[];
}

const AccordionBlock = ({ items }: Props) => {
  if (!!items?.length) {
    return (
      <div className={styles.accordionBlock}>
        {items?.map(
          (item) =>
            item && (
              <Accordion key={item.heading} title={item.heading!}>
                <div dangerouslySetInnerHTML={{ __html: item.body! }} />
              </Accordion>
            ),
        )}
      </div>
    );
  }
  return null;
};

export default AccordionBlock;
